import React, { useState } from "react";
import Tridnio from "../Assets/Img/TRIDNIO2.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="fixed top-0 left-0 w-full z-50 bg-gray-900/80 backdrop-blur-md shadow-lg transition-all">
      <div className="container mx-auto px-6 py-4 flex justify-between items-center">
        {/* Logo */}
        <Link to="/" className="flex-shrink-0">
          <img
            src={Tridnio}
            alt="Logo de Tridnio"
            className="h-auto w-32 md:w-40 object-contain"
          />
        </Link>

        </div>
    </header>
  );
};

export default Navbar;
