import React, { useState, useEffect } from "react";
import Navbar from "../Components/NavBar";
import Footer from "../Components/Footer";
import AOS from "aos";
import "aos/dist/aos.css";

const TridnioMain = () => {
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-black via-gray-900 to-gray-800 text-white font-sans">
      {/* Navbar */}
      <Navbar />

      {/* Hero Section */}
      <section
        id="about-us"
        className="relative flex flex-col items-center justify-center min-h-screen text-center px-6"
      >
        <h1 className="text-6xl font-extrabold mb-6">
          Transforma tu Negocio con <span className="text-lime-500">Tridnio</span>
        </h1>
        <p className="text-gray-400 text-lg max-w-3xl mx-auto mb-8">
          Soluciones tecnológicas diseñadas para mejorar tu productividad, 
          optimizar procesos y llevar tu empresa al siguiente nivel.
        </p>
        <div className="flex space-x-6">
          <button
            className="px-6 py-3 bg-lime-500 text-gray-900 rounded-lg shadow-lg hover:bg-lime-400 transition"
            onClick={() => {
              document.getElementById("services").scrollIntoView({ behavior: "smooth" });
            }}
          >
            Aprende Más
          </button>
          <button
            className="px-6 py-3 bg-gray-700 text-gray-300 rounded-lg shadow-lg hover:bg-gray-600 transition"
            onClick={() => setPopupOpen(true)}
          >
            Ver Servicios
          </button>
        </div>
      </section>

      {/* Popup Modal */}
      {popupOpen && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 text-center">
            <h2 className="text-2xl font-bold text-white mb-4">
              Más Sobre Nuestros Servicios
            </h2>
            <p className="text-gray-400 mb-6">
              Ofrecemos desarrollo web, aplicaciones móviles, diseño UI/UX y
              soluciones backend robustas. Descubre cómo podemos ayudarte.
            </p>
            <button
              className="px-4 py-2 bg-lime-500 text-gray-900 rounded-full hover:bg-lime-400 transition"
              onClick={() => setPopupOpen(false)}
            >
              Cerrar
            </button>
          </div>
        </div>
      )}

      {/* Sección de Servicios */}
      <section
        id="services"
        className="py-20 bg-gradient-to-tl from-gray-800 via-gray-900 to-black"
      >
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-4xl font-bold mb-12">Nuestras Soluciones</h2>
          <p className="text-gray-400 max-w-2xl mx-auto mb-12">
            Descubre cómo nuestras aplicaciones y servicios pueden ayudarte a optimizar tu negocio y crear experiencias únicas para tus clientes.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
            {/* Servicios */}
            <ServiceCard
              title="Desarrollo Web"
              description="Creamos sitios modernos y funcionales que impulsan tu presencia en línea."
              linkHref="/desarrollo-web"
              
            />
            <ServiceCard
              title="Apps Móviles"
              description="Aplicaciones nativas e híbridas diseñadas para mejorar la experiencia del usuario."
              linkHref="/apps-moviles"
              
            />
            <ServiceCard
              title="Backend Escalable"
              description="Infraestructura robusta para soportar el crecimiento de tu negocio."
              linkHref="/backend"
              
            />
            <ServiceCard
              title="Diseño UI/UX"
              description="Interfaces atractivas y fáciles de usar, diseñadas para cautivar a tus usuarios."
              linkHref="/ui-ux"
              
            />
          </div>
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
};

const ServiceCard = ({ title, description, linkHref, linkText }) => (
  <div
    data-aos="fade-up"
    className="bg-gray-700 p-6 rounded-lg shadow-lg text-center"
  >
    <h3 className="text-xl font-semibold text-white mb-4">{title}</h3>
    <p className="text-gray-300 mb-6">{description}</p>
    {linkText && (
      <a
        href={linkHref}
        className="inline-block px-4 py-2 bg-lime-500 text-gray-900 rounded-lg hover:bg-lime-400 transition"
      >
        {linkText}
      </a>
    )}
  </div>
);

export default TridnioMain;
