import React from "react";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-400 py-8">
      <div className="container mx-auto px-6">
        {/* Contacto y Redes Sociales */}
        <div className="flex flex-col md:flex-row justify-between items-center mb-6">
          <div className="text-center md:text-left">
            <p className="text-sm md:text-base">
              ¿Necesitas información? Llama al{" "}
              <a
                href="tel:+50684839675"
                className="text-lime-500 font-semibold hover:underline"
              >
                +506 84839675
              </a>{" "}
              o escribe a{" "}
              <a
                href="mailto:info@tridniostudios.com"
                className="text-lime-500 font-semibold hover:underline"
              >
                info@tridniostudios.com
              </a>
            </p>
          </div>

          {/* Redes Sociales */}
          <div className="flex space-x-4 mt-4 md:mt-0">
            <a
              href="https://www.facebook.com/tridniostudios"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-white transition"
            >
              <FaFacebookF size={20} />
            </a>
            <a
              href="https://www.instagram.com/tridnio"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-white transition"
            >
              <FaInstagram size={20} />
            </a>
          </div>
        </div>

        {/* Links de Navegación */}
        <div className="flex flex-wrap justify-center space-x-6 mb-6">
          <a
            href="#about-us"
            className="text-gray-400 hover:text-lime-500 transition"
          >
            Sobre Nosotros
          </a>
          <a
            href="#services"
            className="text-gray-400 hover:text-lime-500 transition"
          >
            Servicios
          </a>
          <a
            href="#contact"
            className="text-gray-400 hover:text-lime-500 transition"
          >
            Contacto
          </a>
        </div>

        {/* Créditos */}
        <div className="text-center">
          <p className="text-sm">
            © {new Date().getFullYear()} Tridnio Studios. Todos los derechos
            reservados.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
